import React, { ReactNode } from 'react';
import Grid from '../../../components/layout/Grid';
import { Section } from '../../../components/Page';
import Text from '../../../components/Text';

type Props = {
  heading: ReactNode;
  subHeading: ReactNode;
  subHeading2?: ReactNode;
  image: ReactNode;
};

const WhyBuyMainSection = (props: Props) => {
  return (
    <Section padding="sm">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Text el="h1" variant="headingXl" css={{ marginBottom: '3.2rem' }}>
            {props.heading}
          </Text>
          <Text el="p" variant="paragraphLg">
            {props.subHeading}
          </Text>
          {props.subHeading2 && (
            <Text el="p" variant="paragraphMd" css={{ marginTop: '3.2rem' }}>
              {props.subHeading2}
            </Text>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {props.image}
        </Grid>
      </Grid>
    </Section>
  );
};

export default WhyBuyMainSection;
