import React, { ReactNode } from 'react';
import { Theme, useTheme } from '@material-ui/core';
import { css } from '@emotion/react';

import colors from '../../../styles/_colors.module.scss';
import RemoteIcon from '../../../media/richIcons/remote.svg';
import OnboardingIcon from '../../../media/richIcons/onboarding.svg';
import K12Icon from '../../../media/richIcons/k12.svg';
import RecreationIcon from '../../../media/richIcons/recreation.svg';
import CampusOrientationIcon from '../../../media/richIcons/campusOrientation.svg';
import PublicEngagementIcon from '../../../media/richIcons/megaphone.svg';
import HighFiveIcon from '../../../media/richIcons/highFive.svg';
import MoneyIcon from '../../../media/richIcons/money.svg';
import ConferencesIcon from '../../../media/richIcons/conferences.svg';
import GooseFeet from '../../../media/richIcons/gooseFeet.svg';

import Link from '../../../components/Link';
import Text from '../../../components/Text';
import Box from '../../../components/layout/Box';
import Flex from '../../../components/layout/Flex';

type Props = React.PropsWithChildren<{
  active?: boolean;
}>;

const CustomerCard = (props: Props) => {
  const theme = useTheme();

  return (
    <Box p="0 8px">
      <div
        css={[
          {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colors.coreWhite,
            transition: '0.275s all ease-in-out',
            minHeight: '59rem',
            maxWidth: '64rem',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '24px',
            padding: '0 28px 24px 28px',
            borderRadius: 8,
            [theme.breakpoints.up('sm')]: {
              minHeight: '63rem',
            },
            [theme.breakpoints.up('md')]: {
              padding: '0 32px 26px 32px',
            },
          },
          !props.active
            ? {
                transform: 'scale(0.9)',
                opacity: '0.5',
              }
            : undefined,
        ]}
      >
        {props.children}
      </div>
    </Box>
  );
};

const PolaroidPhotos = (props: { imgEl1: ReactNode; imgEl2: ReactNode }) => {
  const theme = useTheme();
  const polaroidBaseCss = css({
    backgroundColor: colors.white,
    boxShadow: '0 0 10px 1px rgba(0,0,0,0.15)',
    padding: '10px 10px 14px 10px',
    [theme.breakpoints.up('md')]: {
      padding: '16px 16px 24px 16px',
    },
  });

  const ImgWrapper = ({ children }: { children: ReactNode }) => (
    <Box width="100%" height="100%">
      {children}
    </Box>
  );

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      css={{
        marginBottom: '4rem',
        padding: '0 16px',
        [theme.breakpoints.up('md')]: {
          padding: '0 28px',
        },
      }}
    >
      <div
        css={[
          polaroidBaseCss,
          {
            marginRight: '10px',
            transform: 'rotate(-7deg)',
            [theme.breakpoints.up('md')]: {
              marginRight: '20px',
            },
          },
        ]}
      >
        <ImgWrapper>{props.imgEl1}</ImgWrapper>
      </div>
      <div
        css={[
          polaroidBaseCss,
          {
            transform: 'rotate(4deg)',
            position: 'relative',
            top: '8px',
          },
        ]}
      >
        <ImgWrapper>{props.imgEl2}</ImgWrapper>
      </div>
    </Flex>
  );
};

const SinglePhoto = (props: React.PropsWithChildren<{}>) => (
  <div
    css={(theme: Theme) => ({
      borderRadius: '16px',
      overflow: 'hidden',
      marginTop: '28px',
      marginBottom: '24px',
      height: '20rem',
      [theme.breakpoints.up('md')]: {
        marginTop: '36px',
        height: '30rem',
      },
    })}
  >
    {props.children}
  </div>
);

const OrganizationTitle = (props: React.PropsWithChildren<{}>) => (
  <Text
    el="h3"
    variant="heading2xs"
    fontWeight="semiBold"
    css={{
      marginBottom: '0.6rem',
      color: colors.coreBlack,
    }}
  >
    {props.children}
  </Text>
);

const MainTitle = (props: React.PropsWithChildren<{}>) => {
  const theme = useTheme();
  return (
    <Text
      el="h4"
      variant="headingSm"
      css={{
        [theme.breakpoints.only('sm')]: {
          fontSize: '3rem',
        },
        [theme.breakpoints.only('xs')]: {
          fontSize: '2.8rem',
        },
      }}
    >
      {props.children}
    </Text>
  );
};

const Quote = (props: React.PropsWithChildren<{}>) => (
  <div
    css={(theme: Theme) => ({
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      minHeight: '15rem',
      [theme.breakpoints.up('md')]: { minHeight: '12rem' },
    })}
  >
    <Text el="p" variant="paragraphLg">
      "{props.children}"
    </Text>
  </div>
);

const useCaseTypes = {
  campus: {
    icon: <CampusOrientationIcon width="100%" />,
    name: 'Campus orientation',
  },
  conferences: {
    icon: <ConferencesIcon width="100%" />,
    name: 'Conferences',
  },
  fundraisers: {
    icon: <MoneyIcon width="100%" />,
    name: 'Fundraisers',
  },
  k12: {
    icon: <K12Icon width="100%" />,
    name: 'K-12',
  },
  onboarding: {
    icon: <OnboardingIcon width="100%" />,
    name: 'Onboarding',
  },
  public: {
    icon: <PublicEngagementIcon width="100%" />,
    name: 'Public engagement',
  },
  recreation: {
    icon: <RecreationIcon width="100%" />,
    name: 'Recreation',
  },
  teamBuilding: {
    icon: <RemoteIcon width="100%" />,
    name: 'Team building',
  },
  tours: {
    icon: <GooseFeet width="100%" />,
    name: 'Tours',
  },
  virtualTeams: {
    icon: <RemoteIcon width="100%" />,
    name: 'Virtual teams',
  },
};

type UseCasesProps = {
  useCaseTypes: Array<keyof typeof useCaseTypes>;
};
const UseCases = (props: UseCasesProps) => (
  <div
    css={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRadius: 8,
    }}
  >
    {props.useCaseTypes.map((useCaseType) => (
      <Flex key={useCaseType} alignItems="center" css={{ padding: '0.8rem 0' }}>
        <Box width="2.4rem" flexShrink={0} mr="16px">
          {useCaseTypes[useCaseType].icon}
        </Box>
        <Text el="p" variant="paragraphSm">
          {useCaseTypes[useCaseType].name}
        </Text>
      </Flex>
    ))}
  </div>
);

const ExternalLink = (props: React.PropsWithChildren<{ href: string; isUntrusted?: boolean }>) => (
  <Link
    href={props.href}
    target="_blank"
    rel={props.isUntrusted ? 'noopener noreferrer' : undefined}
    variant="btnSmOutline"
    css={{ marginTop: '2rem' }}
  >
    {props.children}
  </Link>
);

CustomerCard.PolaroidPhotos = PolaroidPhotos;
CustomerCard.SinglePhoto = SinglePhoto;
CustomerCard.OrganizationTitle = OrganizationTitle;
CustomerCard.MainTitle = MainTitle;
CustomerCard.Quote = Quote;
CustomerCard.UseCases = UseCases;
CustomerCard.ExternalLink = ExternalLink;

export default CustomerCard;
