import React, { ReactNode } from 'react';
import Grid from '../../../components/layout/Grid';
import { Section } from '../../../components/Page';
import Text from '../../../components/Text';
import Link from '../../../components/Link';
import Box from '../../../components/layout/Box';
import Image from '../../../components/Image';

import whySubPattern from '../media/whySubPattern.png';

type Props = {
  title?: ReactNode;
  description?: ReactNode;
  benefits: Array<{
    icon: ReactNode;
    title: ReactNode;
    description: ReactNode;
  }>;
  getStartedHref?: string;
  onGetStartedClick?: (e: React.MouseEvent) => void;
};

const defaultProps: Partial<Props> = {
  title: 'Why subscribe?',
};

const WhyBuySubscribeSection = (props: Props) => {
  return (
    <Section padding="md">
      <Box borderRadius={24} css={{ backgroundColor: 'white', padding: '4rem', position: 'relative' }}>
        <Image
          src={whySubPattern}
          width="1242"
          height="928"
          alt="background pattern"
          css={{ position: 'absolute', top: 0, left: 0, height: '100%', zIndex: 0, objectFit: 'cover' }}
        />
        <div css={{ position: 'relative' }}>
          <Text el="h2" variant="headingMd" align="center">
            {props.title}
          </Text>
          {props.description && (
            <Text el="p" variant="paragraphMd" align="center">
              {props.description}
            </Text>
          )}
          <Grid container spacing={8} justify="center" css={{ marginTop: '32px', marginBottom: '32px' }}>
            {props.benefits.map((benefit, i) => (
              <Grid key={i} item xs={12} md={6} lg={5}>
                {benefit.icon}
                <Text el="h3" variant="headingSm" css={{ margin: '1.6rem 0' }}>
                  {benefit.title}
                </Text>
                <Text el="p" variant="paragraphMd" css={{ maxWidth: '40.2rem' }}>
                  {benefit.description}
                </Text>
              </Grid>
            ))}
          </Grid>
          {props.onGetStartedClick && (
            <Box textAlign="center">
              <Link href={props.getStartedHref} variant="btn" onClick={props.onGetStartedClick}>
                Get started
              </Link>
            </Box>
          )}
        </div>
      </Box>
    </Section>
  );
};

WhyBuySubscribeSection.defaultProps = defaultProps;

export default WhyBuySubscribeSection;
