import React, { useState } from 'react';
import SlickCarousel from 'react-slick';

import colors from '../../../styles/_colors.module.scss';
import { Section } from '../../../components/Page';
import Text from '../../../components/Text';
import Box from '../../../components/layout/Box';
import Carousel from '../../../components/Carousel';
import Button from '../../../components/Button';
import Icon from '../../../components/icons/Icon';
import { css } from '@emotion/react';

type Props = React.PropsWithChildren<{
  bg?: 'blue' | 'lilac';
}>;

const WhyBuyHappyCustomersSection: React.FunctionComponent<Props> = (props) => {
  const carouselRef = React.createRef<SlickCarousel>();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const arrowBtnBaseCss = css({
    color: colors.interactiveVibrantBlue,
    backgroundColor: colors.interactiveHighlighterYellow,
    flexShrink: 0,
    borderRadius: '50%',
    touchAction: 'manipulation', // to disable double tap zoom on mobile devices
  });

  function onLeftArrowClick() {
    carouselRef.current?.slickPrev();
  }

  function onRightArrowClick() {
    carouselRef.current?.slickNext();
  }

  return (
    <Section
      padding="md"
      containerProps={{ maxWidth: 'none', disablePadding: true }}
      css={{ backgroundColor: props.bg === 'blue' ? colors.bgDeepBlue : colors.accentLilac }}
    >
      <Box mb={{ xs: '4.6rem', md: '6rem' }} p="0 32px">
        <Text el="h2" variant="headingMd" align="center" css={{ color: 'white' }}>
          Honks from happy customers!
        </Text>
      </Box>

      <div>
        <Carousel
          ref={carouselRef}
          infinite
          arrows={false}
          centerMode
          centerPadding="300px"
          slidesToShow={1}
          speed={325}
          touchThreshold={15}
          responsive={[
            {
              breakpoint: 2561,
              settings: {
                slidesToShow: 1,
                centerPadding: '450px',
              },
            },
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 1,
                centerPadding: '400px',
              },
            },
            {
              breakpoint: 1500,
              settings: {
                slidesToShow: 1,
                centerPadding: '300px',
              },
            },
            {
              breakpoint: 1280,
              settings: {
                slidesToShow: 1,
                centerPadding: '250px',
              },
            },
            {
              breakpoint: 1150,
              settings: {
                slidesToShow: 1,
                centerPadding: '200px',
              },
            },
            {
              breakpoint: 1040,
              settings: {
                slidesToShow: 1,
                centerPadding: '160px',
              },
            },
            {
              breakpoint: 720,
              settings: {
                slidesToShow: 1,
                centerPadding: '110px',
              },
            },
            {
              breakpoint: 560,
              settings: {
                slidesToShow: 1,
                centerPadding: '50px',
              },
            },
            {
              breakpoint: 426,
              settings: {
                slidesToShow: 1,
                centerPadding: '12px',
              },
            },
          ]}
          beforeChange={(_, nextSlide) => setActiveSlideIndex(nextSlide)}
        >
          {React.Children.map(props.children, (child, index) => {
            if (activeSlideIndex === index && React.isValidElement(child)) {
              return React.cloneElement(child, { active: true });
            }
            return child;
          })}
        </Carousel>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          maxWidth={{ xs: '100%', sm: '50.8rem', md: '80rem' }}
          margin="0.8rem auto 0 auto"
          padding="0 16px"
        >
          <Button
            onClick={onLeftArrowClick}
            color="transparent"
            padding="light"
            css={[arrowBtnBaseCss, { transform: 'rotate(180deg)' }]}
            aria-label="Next customer"
          >
            <Icon name="arrowRight" height="5.4rem" />
          </Button>
          <Button
            onClick={onRightArrowClick}
            color="transparent"
            padding="light"
            css={arrowBtnBaseCss}
            aria-label="Previous customer"
          >
            <Icon name="arrowRight" height="5.4rem" />
          </Button>
        </Box>
      </div>
    </Section>
  );
};

WhyBuyHappyCustomersSection.defaultProps = {
  bg: 'blue',
};

export default WhyBuyHappyCustomersSection;
